import React from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';
import { updateErrorFields } from '../../../redux/slices/submitFormErrorSlice';
import { updateAuthorizationData } from '../../../redux/slices/authorizationDataSlice';

const PropertyManagerField : React.FC<any>= ({ formData,userdata, selectedPropertyManager, setSelectedPropertyManager, dispatch, submitErrorModel, authorizationData }) => {
  let isInternalUser = userdata?.isReadWriteUser && !userdata?.isExternal;
  if (!formData?.showPropertyManager) return null;

  return (
    <Grid item xs={12} md={6}>
      <Grid>
        <label htmlFor="field1">{formData?.showLeaseBroker ? "Leasing Broker / Property Manager" : "Property Manager"}</label>
        {!formData?.disablePropertyManager && (!userdata?.isAdmin && !isInternalUser && <span className="mandatory"> * </span>)}
      </Grid>
      <Grid>
        <Autocomplete
          fullWidth
          size="small"
          disabled={!formData?.isFormEditable || formData?.disablePropertyManager}
          disablePortal
          data-testid="property-select property"
          value={selectedPropertyManager}
          options={formData?.propertyManager ?? []}
          getOptionLabel={(option: any) => option?.name}
          isOptionEqualToValue={(option: any, value: any) => option?.name === value?.name}
          onChange={(event, newInputValue: any) => {
            setSelectedPropertyManager(newInputValue ? newInputValue : { name: "", email: "" });
            dispatch(updateErrorFields({
              ...submitErrorModel.data,
              propManager: false
            }));
            dispatch(updateAuthorizationData({
              ...authorizationData,
              propertyManager: newInputValue ? newInputValue?.name : "",
              propertyManagerEmail: newInputValue ? newInputValue?.email : ""
            }));
          }}
          renderInput={(params) => <TextField {...params} role="property manager"
          error={!formData?.disablePropertyManager && !userdata.isAdmin && !isInternalUser && submitErrorModel.data.propManager} />}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyManagerField;
